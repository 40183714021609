@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 
body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
} */
@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  .scrollbar::-webkit-scrollbar-track {
    /* border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem; */

    @apply bg-gray-200 dark:bg-gray-600;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    @apply bg-gray-300 dark:bg-gray-700;

    border-radius: 0.5rem;
    border: 3px solid;
    @apply border-2 border-gray-200 dark:border-gray-600;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-400 dark:bg-gray-800;
  }

  .scrollbar::-webkit-scrollbar-corner {
    @apply bg-gray-200 dark:bg-gray-600;
  }
}

@layer utilities {
  body::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  body::-webkit-scrollbar-track {
    /* border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem; */

    @apply bg-gray-300 dark:bg-gray-600;
  }

  body::-webkit-scrollbar-thumb {
    @apply bg-gray-400 dark:bg-gray-700;

    border-radius: 0.5rem;
    border: 3px solid;
    @apply border-2 border-gray-300 dark:border-gray-600;
  }

  body::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500 dark:bg-gray-800;
  }

  body::-webkit-scrollbar-corner {
    @apply bg-gray-200 dark:bg-gray-600;
  }
}
